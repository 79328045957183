
import UiInputWrapper from "~/components/UI/UiInputWrapper.vue";
import UiInput from "~/components/UI/UiInput.vue";
import UiButton from "~/components/UI/UiButton.vue";
import googleAnalyticsEvents from "~/mixins/googleAnalyticsEvents";

export default {
  components: { UiButton, UiInput, UiInputWrapper },
  mixins: [googleAnalyticsEvents],
  props: {
    data: {
      type: Object,
      default: null,
    },
    inPopUp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSubscribed: false,
      email: "",
      showBackendError: false,
    };
  },
  watch: {
    email() {
      if (this.showBackendError) {
        this.showBackendError = false;
      }
    },
  },
  methods: {
    async onSubmit() {
      try {
        await this.$api.public.subscribe({
          email: this.email,
        });
        this.email = "";
        this.$refs?.form?.reset();
        this.isSubscribed = true;

        this.gaGenerateLeadEvent("subscribe");

        if (!this.inPopUp) {
          setTimeout(() => {
            this.isSubscribed = false;
          }, 10000);
        } else {
          this.$emit("showSuccessSubscription", true);
        }
      } catch (err) {
        console.log("err - ", err?.response?.data?.errors);
        const error = err?.response?.data?.errors?.[0];
        if (error?.property && error?.message) {
          this.showBackendError = true;
          this.$refs?.form?.setErrors({
            [error.property]: error.message,
          });
        }
      }
    },
  },
};
