
export default {
  inheritAttrs: false,
  props: {
    errorText: {
      type: String,
      default: "",
    },
  },
};
